<template>
  <el-dialog
    :visible.sync="dialogVisible"
    center
    width="80%"
    :close-on-click-modal="false"
    title="添加车队"
    @close="cancel"
  >
    <el-form inline size="small">
      <el-form-item label="车队名">
        <el-input
          placeholder="请输入关键词"
          v-model="conditions.name"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="车队简称">
        <el-input
          placeholder="请输入关键词"
          v-model="conditions.shortname"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          class="buttons"
          @click="handleSearch"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      border
      :data="tableData"
      max-height="400px"
      style="width: 100%"
      row-key="name"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        reserve-selection
        :selectable="selectable"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="序号"
        type="index"
        :index="indexMethod"
        width="60px"
      ></el-table-column>
      <el-table-column show-overflow-tooltip prop="shortname" label="车队简称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="name" label="车队名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="telephone" label="联系电话">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="address" label="地址">
      </el-table-column>
      <el-table-column show-overflow-tooltip label="状态">
        <template v-slot="scope">
          <el-tag size="small" :type="enumStatus[scope.row.fleetStatus].type">{{
            enumStatus[scope.row.fleetStatus].label
          }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="total"
      :page="conditions.page"
      :pageSize="conditions.limit"
      @paging="getPaging"
    />
    <span slot="footer" class="dialog-footer">
      <el-button
        class="buttons"
        size="small"
        type="primary"
        @click="confirmAdd"
        :disabled="checkedIds.length == 0"
        >确 定</el-button
      >
      <el-button class="buttons" size="small" @click="cancel">取 消</el-button>
    </span>
    <el-dialog
      :visible.sync="resultVisible"
      center
      width="80%"
      :close-on-click-modal="false"
      :show-close="false"
      title="分派结果"
      append-to-body
    >
      <el-table :data="resultData" border stripe style="width: 100%">
        <el-table-column label="分派" prop="target"></el-table-column>
        <el-table-column label="结果" prop="success">
          <template v-slot="scope">
            <el-tag
              size="small"
              :type="scope.row.success ? 'success' : 'danger'"
              >{{ scope.row.success ? "成功" : "失败" }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="消息" prop="msg"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="buttons"
          size="small"
          type="primary"
          @click="resultConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-dialog>
</template>
<script>
import Pagination from "@/components/pagination";
export default {
  props: {
    planId: {
      type: String,
    },
  },
  data() {
    return {
      dialogVisible: true,
      planId: "",
      conditions: {
        page: 1,
        limit: 10,
        name: "",
        shortname: "",
      },
      enumStatus: {
        0: { label: "待审核", type: "" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      checkedIds: [],
      total: 0,
      tableData: [],
      resultVisible: false,
    };
  },
  components: {
    Pagination,
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    cancel() {
      this.$emit("cancel");
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/ableDispatchList",
        "get",
        { ...this.conditions, planId: this.planId }
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
    },
    async confirmAdd() {
      let result = [];
      for (let item in this.checkedIds) {
        result.push({
          dispatchType: 1,
          targetId: this.checkedIds[item],
          planId: this.planId,
        });
      }
      const { data } = await this.$http(
        "api1",
        "/api/biz/PlanInfo/dispatchBatch",
        "post",
        result
      );
      console.log(data);
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.resultVisible = true;
        this.resultData = data.data;
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
      console.log(this.checkedIds);
    },
    selectable(row, index) {
      return row.ableDispatch;
    },
    resultConfirm() {
      this.resultVisible = false;
      this.$emit("confirm");
    },
  },
};
</script>
