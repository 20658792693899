<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="padding: 10px 20px">
        <el-button
          @click="goBack"
          type="primary"
          size="small"
          style="position: absolute; right: 10px; top: 10px; min-width: 80px"
          >返回</el-button
        >
        <el-descriptions title="货源信息" :column="2" border>
          <el-descriptions-item label="货源编号">{{
            addForm.pno
          }}</el-descriptions-item>
          <el-descriptions-item label="货物类别">{{
            addForm.cargoType
          }}</el-descriptions-item>
          <el-descriptions-item label="货物名称">{{
            addForm.cargoType
          }}</el-descriptions-item>
          <el-descriptions-item label="货物吨数">
            {{ addForm.plantons + "(吨)" }}
          </el-descriptions-item>
          <el-descriptions-item label="已完成吨数">
            {{ addForm.completedTons + "(吨)" }}
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
      <el-row>
        <el-button
          type="primary"
          class="buttons"
          size="small"
          @click="handleResetDispatch"
          style="margin: 10px 20px"
          >重新分派</el-button
        >
      </el-row>
      <el-row style="padding: 10px 20px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            label="分配车队"
            name="first"
            :disabled="addForm.dispatchStatus == 2"
          >
            <el-button
              type="primary"
              size="small"
              style="float: right"
              @click="handleAddFleet"
              >新增车队</el-button
            >
            <el-table :data="fleetTable" max-height="400px">
              <el-table-column
                show-overflow-tooltip
                label="序号"
                type="index"
                width="50px"
              ></el-table-column>
              <el-table-column prop="name" label="车队名称"> </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="handleRemove(scope.row.dispatchId)"
                    style="color: rgb(254, 90, 36)"
                  >
                    取消分派
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane
            label="分配司机"
            name="second"
            :disabled="addForm.dispatchStatus == 1"
          >
            <el-button
              type="primary"
              size="small"
              style="float: right"
              @click="handleAddDriver"
              >新增司机</el-button
            >
            <el-table :data="driverTable" max-height="400px">
              <el-table-column
                show-overflow-tooltip
                label="序号"
                type="index"
                width="50px"
              ></el-table-column>
              <el-table-column prop="plate" label="车牌号"> </el-table-column>
              <el-table-column prop="driverName" label="司机姓名">
              </el-table-column>
              <el-table-column prop="driverPhone" label="手机号">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="handleRemove(scope.row.dispatchId)"
                    style="color: rgb(254, 90, 36)"
                  >
                    取消分派
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </div>
    <DriverDialog
      v-if="showDriver"
      ref="diverDialogRef"
      :planId="planId"
      @confirm="handleDriverConfirm"
      @cancel="handleDriverCancel"
    ></DriverDialog>
    <FleetDialog
      v-if="showFleet"
      ref="fleetDialogRef"
      :planId="planId"
      @confirm="handleFleetConfirm"
      @cancel="handleFleetCancel"
    ></FleetDialog>
  </div>
</template>
<script>
import DriverDialog from "../module/driverDialog.vue";
import FleetDialog from "../module/fleetDialog.vue";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        pno: "",
        cargoType: "",
        cargoTypeName: "",
        plantons: "",
        dispatchStatus: "",
        completedTons: "",
      },
      planId: this.$route.query.id,
      activeName: "first",
      fleetTable: [],
      driverTable: [],
      showDriver: false,
      showFleet: false,
      showFleet: false,
    };
  },
  components: {
    DriverDialog,
    FleetDialog,
  },
  computed: {},
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$http(
        "api1",
        "/api/biz/PlanInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key];
        }
        if (this.addForm.dispatchStatus == 2) {
          this.activeName = "second";
          this.getDriverTable();
        } else {
          this.activeName = "first";
          this.getFleetTable();
        }
      });
    },
    getFleetTable() {
      this.$http("api1", "/api/biz/PlanInfo/dispatchedFleetList", "get", {
        planId: this.$route.query.id,
      }).then((res) => {
        if (res.data.code != 0) {
          return this.$message.error(res.data.msg);
        }
        this.fleetTable = res.data.data;
      });
    },
    handleClick() {
      if (this.activeName == "first") {
        this.getFleetTable();
      } else {
        this.getDriverTable();
      }
    },
    getDriverTable() {
      this.$http("api1", "/api/biz/PlanInfo/dispatchedCarList", "get", {
        planId: this.$route.query.id,
      }).then((res) => {
        if (res.data.code != 0) {
          this.$message.error(res.data.msg);
          return;
        }
        this.driverTable = res.data.data;
      });
    },
    handleAddFleet() {
      this.showFleet = true;
    },
    handleAddDriver() {
      this.showDriver = true;
    },
    handleDriverConfirm() {
      this.showDriver = false;
      this.getDriverTable();
    },
    handleDriverCancel() {
      this.showDriver = false;
    },
    handleFleetConfirm() {
      this.showFleet = false;
      this.getFleetTable();
    },
    handleFleetCancel() {
      this.showFleet = false;
    },
    handleRemove(id) {
      this.$messageBox
        .confirm("确认取消分派, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          this.$http("api1", "/api/biz/PlanInfo/cancelDispatch", "post", {
            dispatchType: this.activeName == "first" ? 1 : 2,
            dispatchId: id,
          }).then((res) => {
            if (res.data.code != 0) {
              this.$message.error(res.data.msg);
              return;
            }
            this.$message.success("操作成功！");
            if (this.activeName == "first") {
              this.getFleetTable();
            } else {
              this.getDriverTable();
            }
          });
        });
    },
    handleResetDispatch() {
      this.$messageBox
        .confirm("重新分派将清空当前分派, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          this.$http("api1", "/api/biz/PlanInfo/dispatchReset", "post", {
            planId: this.$route.query.id,
          }).then((res) => {
            if (res.data.code != 0) {
              this.$message.error(res.data.msg);
              return;
            }
            this.$message.success("操作成功！");
            if (this.activeName == "first") {
              this.getFleetTable();
            } else {
              this.getDriverTable();
            }
            this.getDetails();
          });
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
